import {EngagementAction, EventType, getInstance, ObjectType} from "@snoam/mono-pulse";


export function trackEngagement(eventLabel: string, targetId: string) {
  const pulse = getInstance();

  const model = pulse.createEngagementModel({
    labelPrefix: "KS",
    label: eventLabel,
    action: EngagementAction.Click,
    type: EventType.ENGAGEMENT,
    object: {
      id: `page:kundeservice:element:${targetId}`,
      type: "UIElement",
      elementType: "Button",
      custom: {}
    },
    origin: {
      id: "page:kundeservice",
      type: ObjectType.PAGE,
      url: window.location.href,
    }
  });

  return pulse.track(model);
}


