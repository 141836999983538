import React from "react";
import { Subtract } from 'utility-types';
import { ClientContextConsumer, IClientContext } from './ClientContextProvider';

export const withClientContext = <P extends IClientContext>(Component: any) =>
	class WithClientContext extends React.PureComponent<Subtract<P, IClientContext>> {
		public render() {
			return (
				<ClientContextConsumer>
					{
						(clientContext: IClientContext) => <Component
							{...this.props}
							{...clientContext}
						/>
					}
				</ClientContextConsumer>
			);
		}
	};
