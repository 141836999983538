import * as React from 'react';
import {Component, createContext} from 'react';
import {ClientShortHandName} from "@snoam/mono-scc";

const debug = require('debug')('kontaktsider:client:themeContext');

enum Themes {
  DEFAULT = 'pinata-bundle.css',
  AP = 'ap-bundle.css',
  SA = 'sa-bundle.css',
  BTI = 'bt-bundle.css',
  VNY = 'vny-bundle.css',
  BYN = 'byn-bundle.css',
  STR = 'str-bundle.css',
  ASK = 'ask-bundle.css',
  SCH = 'sch-bundle.css',
  VG = 'vg-bundle.css',
}

export interface IThemeContext {
  themeCss: Themes
}

const ThemeContext = createContext<IThemeContext>({themeCss: Themes.DEFAULT});

export const ThemeConsumer = ThemeContext.Consumer;

export interface IThemeProviderProps {
  clientShortHandName: ClientShortHandName;
}

export interface IThemeProviderState {
  theme: Themes;
}

export class ThemeProvider extends Component<IThemeProviderProps, IThemeProviderState> {

  public render() {
    const {clientShortHandName} = this.props;
    let theme = Themes[clientShortHandName];

    if (!theme) {
      debug(`Unable to resolve theme for ${clientShortHandName}`);
      theme = Themes.DEFAULT;
    }
    debug(`Theme set to ${clientShortHandName}`);

    return (
      <ThemeContext.Provider value={{themeCss: theme}}>
        {this.props.children}
      </ThemeContext.Provider>
    );
  }
}

