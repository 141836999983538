import {classNames, Container, ContainerWidth, Display, Margin, Padding, Visibility} from '@snoam/pinata';
import * as React from 'react';
import {IWithWPContext, withWPContext} from "../../context/withWPContext";
import Hero from '../Hero/Hero';
import FaqGrid from '../Faq/FaqGrid';
import ContactBox from "../ContactBox/ContactBox";

export interface IBodyProps extends IWithWPContext {
    clientShortHandName: string;
}

const styleClass = {
  bodyText: Padding.PY_4,
  container: classNames(
    Padding.MD_PB_10,
    Padding.MD_PT_4,
  ),
  anchor: classNames(
    Visibility.INVISIBLE,
    Padding.PT_20,
    Margin.MT_NEGATIVE_20,
    Display.FLEX,
  ),
};

class Body extends React.PureComponent<IBodyProps> {

  public render() {
    const {clientShortHandName} = this.props;

    return (
      <React.Fragment>
        <Hero clientShortHandName={clientShortHandName} />
        <Container className={styleClass.container} width={ContainerWidth.WIDE}>
          <div id="faq" className={styleClass.anchor} />
          <FaqGrid />
          <div id="kontakt" className={styleClass.anchor} />
          <ContactBox clientShortHandName={clientShortHandName} />
        </Container>
      </React.Fragment>
    );
  }
}
export default withWPContext(Body);
