import 'react-app-polyfill/ie11';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';

try {
  localStorage.setItem('debug', 'kontaktsider,kontaktsider:*');
} catch(e) {
  // tslint:disable-next-line
  console.log('Couldn\'t enable debug, seems like localStorage is disabled...', e);
}

if (process.env.NODE_ENV !== 'production') {
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
  ReactDOM.render(
    <App
      domain={window.location.hostname}
      href={window.location.href}
      userAgent={window.navigator.userAgent}
    />,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(
    <App
      domain={window.location.hostname}
      href={window.location.href}
      userAgent={window.navigator.userAgent}
    />,
    document.getElementById('root')
  );
}
