import React from "react";
import {Subtract} from 'utility-types';
import {SubscriptionsContextConsumer, ISubscriptionsContext} from "./SubscriptionsContextProvider";

export interface IWithSubscriptionsContext {
  subscriptionsContext: ISubscriptionsContext;
}

export const withSubscriptionsContext = <P extends IWithSubscriptionsContext>(Component: any) =>
  class WithSubscriptionsContext extends React.PureComponent<Subtract<P, IWithSubscriptionsContext>> {
    public render() {
      return (
        <SubscriptionsContextConsumer>
          {
            (subscriptionsContext: ISubscriptionsContext) => <Component
              {...this.props}
              subscriptionsContext={subscriptionsContext}
            />
          }
        </SubscriptionsContextConsumer>
      );
    }
  };
