import {
  BackgroundColor,
  BackgroundPosition,
  BorderColor,
  BorderStyle,
  BorderWidth,
  Button,
  ButtonSize,
  ButtonSkin,
  classNames,
  Container,
  ContainerWidth,
  Display,
  FontFamily,
  FontWeight,
  IWithResponsiveProps,
  Loader,
  LoaderColor,
  Margin,
  Padding,
  TextAlign,
  TextColor,
  FontSize,
  Width,
  withResponsive, JustifyContent, FlexDirection, AlignItems,
} from '@snoam/pinata';
import * as React from 'react';
import {IWithWPContext, withWPContext} from "../../context/withWPContext";
import Incident, {IIncidentProps} from "../Incidents/Incident";
import {trackEngagement} from "../pulse/Events";
import {IWithSubscriptionsContext, withSubscriptionsContext} from '../../context/withSubscriptionsContext';
import {clientName, ClientShortHandName} from "@snoam/mono-scc";

export interface IHeroProps extends IWithWPContext, IWithResponsiveProps, IWithSubscriptionsContext {
  clientName?: string;
  clientShortHandName: string;
}

const styleClass = {
  actions: (mobile) => classNames(
    "hero--actions",
    Display.FLEX,
    JustifyContent.JUSTIFY_CENTER,
    Margin.LG_ML_0,
    Padding.PB_8,
    mobile ? FlexDirection.FLEX_COL : FlexDirection.FLEX_ROW,
    mobile ? Padding.PX_8 : null,
    mobile ? null : AlignItems.ITEMS_CENTER,
    mobile ? null : Margin.ML_4,
  ),
  container: classNames(
    'hero--container',
    Display.FLEX,
  ),
  content: classNames(
    'hero--content',
    Display.FLEX,
    Width.W_FULL,
  ),
  contentWrapper: classNames(
    Width.W_FULL,
  ),
  image: (mobile) => classNames(
    'hero--image',
    mobile? BackgroundPosition.BG_CENTER : null,
  ),
  changeSubscription: {
    text: (mobile) => classNames(
      Margin.MT_8,
      Margin.MB_8,
      TextColor.TEXT_PRIMARY_LIGHT,
      FontWeight.FONT_LIGHT,
      TextAlign.TEXT_CENTER,
      mobile ? null : null,
      mobile ? null : BorderWidth.BORDER_T_1,
      mobile ? null : BorderStyle.BORDER_SOLID,
      mobile ? null : BorderColor.BORDER_NEUTRAL_3,
    ),
    link: (mobile) => classNames(
      Margin.MD_ML_6,
      mobile ? Display.BLOCK : null,
      mobile ? Margin.MX_AUTO : null,
    ),
  },
  title: classNames(
    Margin.MT_8,
    Padding.PT_10,
    Padding.MD_PB_6,
    TextColor.TEXT_PRIMARY_LIGHT,
    FontFamily.FONT_TITLE_1,
    FontSize.TEXT_2XL,
    FontSize.MD_TEXT_4XL,
    TextAlign.TEXT_CENTER,
  ),
  wrapper: (mobile) => classNames(
    {
      [BackgroundColor.BG_NEUTRAL_1]: !mobile,
    }
    ,
  ),
};

class Hero extends React.Component<IHeroProps> {

  private renderIncidents = () => {

    const applicableForCurrentSubscriptions: (props: Pick<IIncidentProps, 'bundleCodes'> & IWithSubscriptionsContext) => boolean = props => {
      const { bundleCodes } = props;
      const { subscriptions } = (props.subscriptionsContext.state || { subscriptions: [] });
      if ((bundleCodes || []).length && subscriptions.length) {
        const subscriptionBundleCodes = subscriptions.map(s => s.bundleCode);
        const matchingSubscriptionBundleCodes = (bundleCodes || []).filter(b => {
          return subscriptionBundleCodes.find(bundleCode => bundleCode === b || new RegExp(b).test(bundleCode))
        });

        if (!matchingSubscriptionBundleCodes.length) {
          return false;
        }
      }

      return true;
    };

    const {incidents} = this.props;
    return incidents.map((incident, i) => {
      const props = { bundleCodes: incident.bundleCodes, subscriptionsContext: this.props.subscriptionsContext };
      if (applicableForCurrentSubscriptions(props)) {
        return <Incident {...incident} key={incident.id} id={`incident-${i}`} />;
      }
      return null;
    }).filter(i => i).slice(0, 4);
  };

  private getMyPageUrl = () => {
    const ursMyPageUrls = {
      [ClientShortHandName.E24]: "https://e24.no/minside",
      [ClientShortHandName.DP]: "https://e24.no/minside",
      [ClientShortHandName.SA]: "https://aftenbladet.no/minsidesjekk",
      [ClientShortHandName.BTI]: "https://bt.no/minsidesjekk",
      [ClientShortHandName.AP]: "https://aftenposten.no/minsidesjekk"
    }
    return ursMyPageUrls[this.props.clientShortHandName] || '/minside';
  }

  private renderLoader = () => {
    return(
      <div className={classNames(TextAlign.TEXT_CENTER, Margin.MT_4)}>
        <Loader color={LoaderColor.WHITE} text={"Laster"}/>
      </div>
    )
  };

  private renderIncidentsBlock = () => {
    const {mobile , incidentsLoaded} = this.props;

    return (
      <div className={styleClass.contentWrapper}>
        <h1 className={styleClass.title}>Hvordan kan vi hjelpe deg?</h1>
        <div className={styleClass.actions(mobile)}>
          {incidentsLoaded ? this.renderIncidents() : this.renderLoader()}
        </div>
        <p className={styleClass.changeSubscription.text(mobile)}>
          Vil du gjøre endringer i ditt abonnement?
          <Button
            className={styleClass.changeSubscription.link(mobile)}
            onClick={() => trackEngagement(`Click on min side link`, 'myPage')}
            text={"Min Side"}
            size={ButtonSize.SMALL}
            skin={ButtonSkin.SECONDARY}
            href={this.getMyPageUrl()}
          />
        </p>
      </div>
    )
  };

  public render() {
    const {clientShortHandName, mobile} = this.props;

    return (
      <div className={styleClass.wrapper(mobile)}>
        <div className={styleClass.image(mobile)}
             style={{backgroundImage: `url('https://snotech-media.s3.amazonaws.com/kundeportalen/kontaktsider/${clientShortHandName.toLowerCase()}-bakgrunn.png')`}}>
          <Container className={styleClass.container} width={ContainerWidth.WIDE}>
            <div className={styleClass.content}>
              {this.renderIncidentsBlock()}
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default withResponsive(withSubscriptionsContext<any>(withWPContext(Hero)));
