import React from "react";
import {IWithPulseEvent, withPulseEvent} from "./withPulseEvent";
import {IWithSpidContext, withSpidContext} from "../context/withSpidContext";


type IWithPulseAndSpidContenxt = IWithPulseEvent & IWithSpidContext;
interface IPulseEventProps extends IWithPulseAndSpidContenxt {
  model: any;
}

class PulseEvent extends React.PureComponent<IPulseEventProps> {

  componentDidMount() {
    const {pulse, model} = this.props;
    pulse.track(model);
  }

  render() {
    return null;
  }
}

export default  withSpidContext(withPulseEvent(PulseEvent));
