import React from 'react';
import WPService, {
  IFAQ,
  IFAQCategory,
  IFAQPost,
  IIncident,
  IChat,
  IFeedbackCategories,
  IContact,
  IFooterMenu, IContactFormMapping, IMaintenanceStatus, IMaintenance
} from '../services/WPService';

const debug = require('debug')('kontaktsider:client:WPContext');

export interface IWPActions {
  getFAQ: () => Promise<IFAQ>;
  getIncidents: () => Promise<IIncident[]>;
  getChat: () => Promise<IChat[]>;
  getFeedbackCategories: () => Promise<IFeedbackCategories[]>;
  getContact: () => Promise<IContact>;
  getFooterMenu: () => Promise<IFooterMenu[]>;
  getContactFormMapping: () => Promise<IContactFormMapping[]>;
  sendFeedback: (spid: string, sig: string, category: string, subCategory: string, feedback: string) => Promise<any>;
}

export interface IWPContextState {
  faq: IFAQ;
  incidents: IIncident[];
  incidentsLoaded: boolean;
  chat: IChat;
  feedbackCategories: IFeedbackCategories[];
  contact: IContact;
  footerMenu: IFooterMenu[];
  contactFormMapping: IContactFormMapping[];
  maintenance: IMaintenance | null,
}

export interface IWPContextProps {
  hostname: string;
}

export interface IWPContext {
  actions: IWPActions;
  state: IWPContextState;
}

const WPContext = React.createContext<IWPContext>({
  state: {} as any,
  actions: {} as any
});

export const WPConsumer = WPContext.Consumer;

class WPProvider extends React.Component<IWPContextProps, IWPContextState> {

  private service: WPService;

  constructor(props: any) {
    super(props);
    this.state = {
      faq: {
        categories: [] as IFAQCategory[],
        posts: [] as IFAQPost[]
      },
      incidents: [] as IIncident[],
      incidentsLoaded: false,
      chat: {} as IChat,
      contact: {} as IContact,
      footerMenu: [],
      feedbackCategories: [] as IFeedbackCategories[],
      contactFormMapping: [] as IContactFormMapping[],
      maintenance: null,
    };
    this.service = new WPService();
  }

  public componentDidMount() {
    this.getFAQ()
      .then((response) => {
        this.setState({
          faq: response
        })
      })
      .catch(e => debug('No response from WP when fetching FAQ. Response: %o', e));
    this.getIncidents()
      .then((response: IIncident[]) => {
        this.setState({
          incidents: response.sort((a, b) =>
            parseInt(a.priority, 0) - parseInt(b.priority, 0)),
          incidentsLoaded: true
        })
      })
      .catch(e => debug('No response from WP when fetching incidents. Response: %o', e));
    this.getChat()
      .then((response: IChat) => {
        this.setState({
          chat: response,
        })
      })
      .catch(e => debug('No response from WP when fetching Chat. Response: %o', e));
    this.getFeedbackCategories()
      .then((response: IFeedbackCategories[]) => {
        this.setState({
          feedbackCategories: response,
        })
      })
      .catch(e => debug('No response from WP when fetching Feedback Categories. Response: %o', e));
    this.getContact()
      .then((response) => {
        this.setState({
          contact: response
        })
      })
      .catch(e => debug('No response from WP when fetching Contact information. Response: %o', e));
    this.getFooterMenu()
      .then((response) => {
        this.setState({
          footerMenu: response
        })
      })
      .catch(e => debug('No response from WP when fetching Footer menu. Response: %o', e));
    this.getContactFormMapping()
      .then((response) => {
        this.setState({contactFormMapping: response})
      })
      .catch(e => debug('No response from WP when fetching Contact Form Mapping. Response: %o', e));
    this.getMaintenanceStatus()
      .then((response) => {
        this.setState({
          maintenance: {
            active: response.maintenenceActive,
            message: response.maintenenceText
          }
        });
      })
      .catch(e => {
        this.setState({
          maintenance: {
            active: false,
            message: ''
          }
        })
        debug('No response from WP when fetching settings. Response: %o', e)
      });
  }

  private getFAQ = () => {
    const {hostname} = this.props;
    return this.service.getFAQ(hostname);
  };

  private getIncidents = () => {
    const {hostname} = this.props;
    return this.service.getIncidents(hostname);
  };

  private getChat = () => {
    const {hostname} = this.props;
    return this.service.getChat(hostname);
  };

  private getFeedbackCategories = () => {
    const {hostname} = this.props;
    return this.service.getFeedbackCategories(hostname);
  };

  private getContact = () => {
    const {hostname} = this.props;
    return this.service.getContact(hostname);
  };

  private getFooterMenu = () => {
    const {hostname} = this.props;
    return this.service.getFooterMenu(hostname);
  };

  public sendFeedback = (spid: string, sig: string, category: string, subCategory: string, feedback: string) => {
    const {hostname} = this.props;
    return this.service.sendFeedback(hostname, spid, sig, category, subCategory, feedback);
  };

  private getContactFormMapping = () => {
    const {hostname} = this.props;
    return this.service.getContactFormMapping(hostname);
  };

  private getMaintenanceStatus = () => {
    const {hostname} = this.props;
    return this.service.getMaintenanceStatus(hostname);
  }

  public render() {
    const {state} = this;

    const actions = {
      getFAQ: this.getFAQ,
      getIncidents: this.getIncidents,
      sendFeedback: this.sendFeedback,
      getChat: this.getChat,
      getFeedbackCategories: this.getFeedbackCategories,
      getContact: this.getContact,
      getFooterMenu: this.getFooterMenu,
      getContactFormMapping: this.getContactFormMapping
    };

    const context = {
      actions,
      state
    };

    return <WPContext.Provider value={context}>
      {this.state.maintenance && this.props.children}
    </WPContext.Provider>

  }
}

export default WPProvider;
