import React from "react";
import { Subtract } from 'utility-types';
import { IFaqGridContextActions, IFaqGridContextState, FaqGridConsumer, IFaqGridContext } from './FaqGridContextProviver';


export interface IWithFaqGridContext {
  faqContext: {
    actions: IFaqGridContextActions;
    state: IFaqGridContextState;
  }
}

export const withFaqGridContext = <P extends IWithFaqGridContext>(Component: any) =>
  class WithFaqGridContext extends React.PureComponent<Subtract<P, IWithFaqGridContext>> {
    public render() {
      return (
        <FaqGridConsumer>
          {
            (faqGridContext: IFaqGridContext) => <Component
              faqContext={faqGridContext}
            />
          }
        </FaqGridConsumer>
      );
    }
  };
