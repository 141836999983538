import {
  AlignItems,
  BackgroundColor,
  BorderRadius,
  Button,
  ButtonSize,
  ButtonSkin,
  Card,
  classNames,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontStyle,
  FontWeight,
  Height,
  Icon,
  IconColor,
  IconName,
  IconSize,
  IWithResponsiveProps,
  JustifyContent,
  Link,
  Margin,
  Padding,
  Resize,
  TextAlign,
  TextColor,
  TextDecoration,
  Width,
  withResponsive,
  Cursor,
} from '@snoam/pinata';
import Select from '../Select/Select';
import * as React from 'react';
import {IWithWPContext, withWPContext} from "../../context/withWPContext";
import {IWithSpidContext, withSpidContext} from "../../context/withSpidContext";
import {trackEngagement} from "../pulse/Events";
import ReactHtmlParser from 'react-html-parser';
import {isChatOpen} from "@snoam/mono-sf-chat";
import {ClientShortHandName} from "@snoam/mono-scc";
import {getUrsContactFormUrl} from 'utils';


export interface IContactBoxProps extends IWithWPContext, IWithResponsiveProps, IWithSpidContext {
  className?: string
  clientShortHandName: string;
}

export interface IContactBoxState {
  mainCategories: string[],
  chosenCategory: string,
  subCategories: string[],
  chosenSubCategory: string,
  textareaHasValue: boolean,
  textareaLength: number;
  isLoadingForm: boolean,
  textareaValue: string,
  contactSuccess: boolean,
  error: boolean,
  showFeedbackForm: boolean,
  feedbackFormHelpText: string,
  chatOpen: boolean,
}

const styleClass = {
  call: classNames(
    TextAlign.TEXT_CENTER,
    TextColor.TEXT_NEUTRAL_6,
    FontFamily.FONT_TITLE_1,
    FontWeight.FONT_NORMAL,
    Margin.MB_8,
  ),
  chatIcon: classNames(
    Display.BLOCK,
    Margin.MX_AUTO,
    Margin.MT_4,
  ),
  chatLink: classNames(
    Padding.PY_8,
    TextAlign.TEXT_CENTER,
    Width.W_FULL,
    Display.BLOCK,
    FontWeight.FONT_LIGHT,
    TextDecoration.NO_UNDERLINE,
  ),
  chatLinkText: classNames(
    TextColor.TEXT_PRIMARY_1,
    FontSize.TEXT_3XL,
    Display.BLOCK,
  ),
  chatInfoText: classNames(
    Display.BLOCK,
    FontSize.TEXT_BASE,
    FontWeight.FONT_LIGHT,
    TextAlign.TEXT_CENTER,
    Margin.MT_2,
    Margin.MB_6,
    TextColor.TEXT_NEUTRAL_7,
  ),
  chatArrow: classNames(
    'contactbox--chat--arrow',
  ),
  contactCard: classNames(
    Margin.MT_2,
  ),
  contactForm: classNames(
    Width.W_FULL,
    Width.MD_W_1_2,
  ),
  container: (className) => classNames(
    className,
    Padding.PT_10,
    'contactbox',
  ),
  content: classNames(
    Padding.MD_P_3,
  ),
  contactTextarea: classNames(
    Height.H_32,
    Width.W_FULL,
    Resize.RESIZE_NONE,
    FontSize.TEXT_SM,
    Padding.P_3,
    FontFamily.FONT_BODY,
    FontSize.TEXT_BASE,
    BackgroundColor.BG_NEUTRAL_1,
  ),
  contactTextareaMaxLength: classNames(
    FontSize.TEXT_BASE,
    TextColor.TEXT_NEUTRAL_6,
    FontStyle.ITALIC
  ),
  emailIcon: classNames(
    Margin.MT_4,
    Margin.MB_4,
  ),
  loggedIn: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    AlignItems.ITEMS_CENTER,
  ),
  loggedOutText: classNames(
    FontSize.TEXT_BASE,
    TextColor.TEXT_NEUTRAL_6,
    Margin.MY_2,
    Display.FLEX,
    FlexDirection.FLEX_COL,
    FlexDirection.MD_FLEX_ROW,
    AlignItems.ITEMS_CENTER,
    JustifyContent.JUSTIFY_CENTER,
    TextAlign.TEXT_CENTER,
    FontWeight.FONT_LIGHT,
  ),
  loggedOutLink: classNames(
    Margin.MR_1,
    Display.INLINE_BLOCK,
  ),
  loggedOutIcon: classNames(
    Margin.MD_MR_4,
    Margin.MB_2,
    Margin.MD_MB_0,
  ),
  title: classNames(
    TextColor.TEXT_NEUTRAL_6,
    TextAlign.TEXT_CENTER,
    FontFamily.FONT_TITLE_1,
    FontSize.TEXT_XL,
    Margin.MY_6,
    FontFamily.FONT_TITLE_2,
    FontSize.TEXT_3XL,
    FontWeight.FONT_LIGHT,
  ),
  scrollButton: classNames(
    "contactbox--scrollbutton",
    BorderRadius.ROUNDED_T_BRAND,
    Padding.PL_10,
    Padding.PY_3,
    Margin.ML_6,
    Display.INLINE_FLEX,
    AlignItems.ITEMS_CENTER,
    FontFamily.FONT_TITLE_1,
    BackgroundColor.BG_NEUTRAL_1,
    TextColor.TEXT_NEUTRAL_6,
    Width.W_AUTO,
  ),
  select: classNames(
    Padding.PB_1,
  ),
  text: classNames(
    TextColor.TEXT_NEUTRAL_6,
    Margin.MB_4,
    FontWeight.FONT_LIGHT,
    TextAlign.TEXT_CENTER,
    TextAlign.MD_TEXT_LEFT,
  ),
  helpText: classNames(
    FontSize.TEXT_SM,
    Padding.PY_4,
    Padding.PL_1,
    TextColor.TEXT_NEUTRAL_7,
  ),
  externalLinkText: classNames(
    FontSize.TEXT_BASE,
    TextColor.TEXT_NEUTRAL_6,
    FontWeight.FONT_LIGHT,
    Margin.MY_3,
  ),
  externalLink: classNames(
    Cursor.CURSOR_POINTER,
    TextColor.HOVER_TEXT_PRIMARY_3,
  )
};

class ContactBox extends React.Component<IContactBoxProps, IContactBoxState> {

  constructor(props) {
    super(props);

    this.state = {
      mainCategories: [],
      chosenCategory: '',
      subCategories: [],
      chosenSubCategory: '',
      textareaHasValue: false,
      textareaLength: 0,
      isLoadingForm: false,
      contactSuccess: false,
      textareaValue: '',
      error: false,
      showFeedbackForm: true,
      feedbackFormHelpText: '',
      chatOpen: false,
    }
  }

  private handleChatClick = () => {
    trackEngagement("Click on chat", 'chat');
    const chatLauncher: any = document.getElementsByClassName('trigger-chatbot-button');

    if(chatLauncher.length > 0) {
      chatLauncher[0].click();
    }
  };

  private handleChatLinkKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.handleChatClick();
    }
    return;
  };

  private renderChat = (chat) => {
    if (chat) {
      return (
        <Link className={styleClass.chatLink} tabIndex={0} onClick={this.handleChatClick}
              onKeyPress={(event) => this.handleChatLinkKeyPress(event)}>
          <Icon
            name={IconName.QUESTION_ANSWER}
            color={IconColor.PRIMARY_1}
            size={IconSize.LARGE}
            className={styleClass.chatIcon}
          />
          <span className={styleClass.chatLinkText}>Chat med oss</span>
          <span className={styleClass.chatInfoText}>Chatten er åpen.</span>
        </Link>
      );
    }
    return;
  };

  private getMainCategories = (feedbackCategories) => {
    const mainCategories = feedbackCategories.map(category => {
      return category.value;
    });

    mainCategories.unshift("Hva gjelder din henvendelse?");
    this.setState({
      mainCategories,
    });
  }

  componentDidMount() {
    this.getMainCategories(this.props.feedbackCategories);
    this.setState({
      chatOpen: isChatOpen(new Date(), ClientShortHandName[this.props.clientShortHandName])
    });
  };

  componentDidUpdate(prevProps) {
    const {feedbackCategories} = this.props;

    if (prevProps.feedbackCategories !== feedbackCategories) {
      this.getMainCategories(feedbackCategories);
    }
    // Scroll to Anchor
    if (document) {
      const url = document.location.href;
      const id = url.includes("#faq") ? 'faq' : url.includes("#kontakt") ? 'kontakt' : ''
      const scrollToAnchor = () => {
        const anchorTarget = document.getElementById(id);
        anchorTarget && anchorTarget.scrollIntoView({behavior: 'smooth', block: 'start'});
      }
      if (id !== '') {
        scrollToAnchor();
      }
    }
  }

  private onTextareaChange = (event) => {
    if (event.target.value) {
      this.setState({
        textareaHasValue: true,
        textareaValue: event.target.value,
        textareaLength: event.target.value.length
      });
    }
  };

  private resetContactTexareaLength = (event) => {
    const {textareaLength} = this.state;
    if (event.keyCode === 8 && textareaLength === 1) {
      this.setState({textareaLength: 0});
    }
  }

  private onMainCategoryChange = (event) => {
    if (this.state.chosenCategory !== '') {
      this.setState({
        chosenCategory: 'Hva gjelder din henvendelse?',
        subCategories: [],
        chosenSubCategory: '',
        showFeedbackForm: false,
        feedbackFormHelpText: ''
      })
    }

    const subCategories = this.props.feedbackCategories.find(category => category.value === event.target.value);

    this.setState({
      chosenCategory: event.target.value
    });

    if (subCategories) {
      const subCategoryArray = subCategories.subArea.map(subArea => subArea.value);
      subCategoryArray.unshift("Velg underkategori");
      this.setState({subCategories: subCategoryArray})
    }
  };

  private onSubCategoryChange = (event) => {
    const chosenSubCategory = event.target.value !== "Velg underkategori" ? event.target.value : '';
    this.setState({
      chosenSubCategory
    });

    const mapping = this.props.contactFormMapping;
    const mappedMainCategory = mapping.find((cat) => cat.name === this.state.chosenCategory);
    const mappedSubCategory = mapping.find((category) => category.name === event.target.value);

    if (mappedMainCategory && mappedSubCategory && mappedMainCategory.id === mappedSubCategory.parent) {
      this.setState({
        showFeedbackForm: Boolean(mappedSubCategory.showFeedbackForm),
        feedbackFormHelpText: mappedSubCategory.description
      });
    } else {
      this.setState({showFeedbackForm: true, feedbackFormHelpText: ''});
    }
  };

  private submitContactForm = (e: any) => {
    e.preventDefault();
    const {chosenCategory, chosenSubCategory} = this.state;
    const spid = this.props.spidContext.state.userId;
    const sig = this.props.spidContext.state.sig;

    this.setState({isLoadingForm: true});

    this.props.actions.sendFeedback(spid, sig, chosenCategory, chosenSubCategory, this.state.textareaValue)
      .then(res => {
        this.setState({contactSuccess: true});
        trackEngagement(`Form submitted [${chosenCategory} > ${chosenSubCategory}]`, 'form');
      })
      .catch(err => this.setState({error: true}));

    this.setState({isLoadingForm: false});
  };

  private cancelContactForm = () => {
    this.setState({
      chosenCategory: '',
      subCategories: [],
      chosenSubCategory: '',
      textareaHasValue: false,
      textareaValue: '',
    });
  };

  private renderForm = () => {
    const {
      subCategories,
      mainCategories,
      chosenSubCategory,
      textareaHasValue,
      textareaLength,
      contactSuccess,
      showFeedbackForm,
      feedbackFormHelpText
    } = this.state;
    const contactUrl = getUrsContactFormUrl(this.props.clientShortHandName as ClientShortHandName)
    if (contactUrl) {
      return (
        <div className={TextAlign.TEXT_CENTER}>
            <Icon name={IconName.EMAIL_OUTLINE} color={IconColor.PRIMARY_3} size={IconSize.LARGE} className={styleClass.chatIcon} />
            <p className={styleClass.externalLinkText}>
              <a href={contactUrl} className={styleClass.externalLink}>Klikk her</a>
              &nbsp;for å sende oss en henvendelse.
            </p>
        </div>
      );
    }

    const spidLoggedIn = this.props.spidContext.state.userId;
    if (mainCategories.length > 0) {
      if (spidLoggedIn) {
        if (!contactSuccess) {
          return (
            <div className={styleClass.loggedIn}>
              <Icon name={IconName.EMAIL_OUTLINE} color={IconColor.PRIMARY_3} size={IconSize.LARGE}
                    className={styleClass.emailIcon}/>
              <p className={styleClass.text}>Send oss en henvendelse nedenfor, og vi kontakter deg i løpet av 24
                timer</p>

              <form className={styleClass.contactForm} onSubmit={this.submitContactForm}>
                <Select
                  options={mainCategories}
                  value={this.state.chosenCategory}
                  onChange={this.onMainCategoryChange}
                  className={styleClass.select}
                />

                {subCategories.length > 0 ?
                  <Select options={subCategories} onChange={this.onSubCategoryChange} className={styleClass.select}/>
                  : null}

                {chosenSubCategory !== '' && showFeedbackForm ?
                  <React.Fragment>
                    {feedbackFormHelpText ?
                      <p className={styleClass.helpText}>{ReactHtmlParser(feedbackFormHelpText)}</p>
                      : null}
                    <textarea
                      className={styleClass.contactTextarea}
                      placeholder={"Skriv inn din beskjed til oss"}
                      onChange={this.onTextareaChange}
                      onKeyDown={this.resetContactTexareaLength}
                      maxLength={2000}
                    />
                    <span
                      className={styleClass.contactTextareaMaxLength}>{`${2000 - textareaLength} bokstaver igjen.`}</span>
                    <div
                      className={classNames(Display.FLEX, JustifyContent.JUSTIFY_CENTER, FlexDirection.FLEX_COL, FlexDirection.MD_FLEX_ROW)}>
                      <Button
                        type="submit"
                        size={ButtonSize.SMALL}
                        disabled={!textareaHasValue}
                        text={"Send inn"}
                        className={classNames(Margin.MB_0, Margin.MD_MR_2)}
                      />
                      <Button
                        size={ButtonSize.SMALL}
                        onClick={this.cancelContactForm}
                        skin={ButtonSkin.SECONDARY}
                        text={"Avbryt"}
                      />
                    </div>
                  </React.Fragment>
                  : <p className={styleClass.helpText}>{ReactHtmlParser(feedbackFormHelpText)}</p>}
              </form>

            </div>
          )
        } else {
          return (
            <React.Fragment>
              <p className={classNames(Padding.PT_8, Margin.MB_2, Margin.ML_1, TextColor.TEXT_NEUTRAL_6)}>
                Takk for din tilbakemelding.
              </p>
              <p className={classNames(Margin.MB_4, Padding.PB_10, Margin.ML_1, TextColor.TEXT_NEUTRAL_6)}>
                Normal behandlingstid er innen 24 timer.
              </p>
            </React.Fragment>
          )
        }
      } else {
        return (
          <div className={TextAlign.TEXT_CENTER}>
            <p className={styleClass.loggedOutText}>
              <Icon name={IconName.EMAIL_OUTLINE} color={IconColor.PRIMARY_3} className={styleClass.loggedOutIcon}/>
              <span>Logg deg inn via menyen i toppen for å
              sende oss en henvendelse, og vi kontakter deg i løpet av 24 timer.</span>
            </p>
          </div>
        )
      }
    }
    return;
  };

  public render() {
    const {className, chat, maintenance} = this.props;
    if(maintenance.active) {
      return null;
    }
    return (
      <section className={styleClass.container(className)}>

        <div className={styleClass.content}>
          <h2 className={styleClass.title}>Kontakt oss</h2>

          {this.state.chatOpen &&
              <Card backgroundColor={BackgroundColor.BG_WHITE} className={styleClass.contactCard}>
                {this.renderChat(chat)}
              </Card>}
          {!this.state.chatOpen &&
              <Card backgroundColor={BackgroundColor.BG_WHITE} className={styleClass.contactCard}>
                {this.renderForm()}
              </Card>}
        </div>
      </section>
    );
  }
}

export default withResponsive(withWPContext<any>(withSpidContext<any>(ContactBox)));
