import * as React from 'react';
import {IFAQPost} from '../../services/WPService';
import {
  Accordion, AccordionIconPlacement,
  AccordionPanel,
  BackgroundColor,
  classNames,
  Display,
  FlexDirection,
  FontSize,
  Icon, IconColor, IconName,
  IWithResponsiveProps,
  LineHeight,
  Margin,
  TextColor,
  withResponsive,
} from '@snoam/pinata';
import FaqListItem from './FaqListItem';

interface IFaqListProps extends IWithResponsiveProps {
  faqPosts: IFAQPost[];
  postToExpand: IFAQPost | undefined;
}

const styleClass = {
  root: (mobile) => classNames(
    'kontaktsider--faq-list',
    mobile ? BackgroundColor.BG_NEUTRAL_1 : BackgroundColor.BG_WHITE,
  ),
  accordionTitle: classNames(
    TextColor.TEXT_PRIMARY_3,
    FontSize.TEXT_LG,
    Display.FLEX,
    FlexDirection.FLEX_ROW_REVERSE,
    BackgroundColor.HOVER_BG_NEUTRAL_1,
    BackgroundColor.FOCUS_BG_NEUTRAL_2,
    LineHeight.LEADING_TIGHT,
  ),
};

class FaqList extends React.PureComponent<IFaqListProps> {

  public render() {
    const {faqPosts, postToExpand, mobile} = this.props;

    return (
      <div className={styleClass.root(mobile)}>
        {faqPosts.length > 0 ?
          <Accordion
            iconOpen={<Icon
              name={IconName.ADD}
              color={IconColor.PRIMARY_1}
              className={Margin.MR_4}
              screenReaderText={'Ikon som viser at man kan utvide seksjon for å se mer info'}
            />}
            iconClose={<Icon
              name={IconName.MINUS}
              color={IconColor.PRIMARY_1}
              className={Margin.MR_4}
              screenReaderText={'Ikon som viser at man kan stenge seksjon for å skjule ekstra info'}
            />}
            titleClasses={styleClass.accordionTitle}
            iconPlacement={AccordionIconPlacement.LEFT}
          >
            {
              faqPosts.map((post: IFAQPost, key: number) => {
                return (
                  <AccordionPanel
                    key={`faq-post-${key}`}
                    title={post.title}
                    expand={postToExpand && post.id === postToExpand.id ? true : false}
                    className={`faq-post-${post.id}`}
                  >
                    <FaqListItem post={post}/>
                  </AccordionPanel>
                );
              })
            }
          </Accordion>
          : null}
      </div>
    )
  }
}

export default withResponsive(FaqList);
