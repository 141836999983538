import * as React from 'react';
import ReactDOM from 'react-dom';
import {IWithWPContext, withWPContext} from '../../context/withWPContext';
import {IFAQCategory, IFAQPost} from '../../services/WPService';
import FaqGridBlock from './FaqGridBlock';
import {
  BackgroundColor,
  BorderColor,
  BorderStyle,
  BorderWidth,
  classNames,
  Container,
  Display, FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  Grid, GridComposition,
  GridGutter,
  Heading,
  HeadingLevel,
  IWithResponsiveProps,
  Loader,
  Margin,
  Padding,
  Position,
  TextAlign,
  Width,
  withResponsive
} from '@snoam/pinata';
import FaqList from './FaqList';
import {IWithFaqGridContext, withFaqGridContext} from '../../context/withFaqGridContext';
import {scrollToTarget} from '../../utils';

interface IFaqGridProps extends IWithWPContext, IWithResponsiveProps, IWithFaqGridContext {
}

interface IFaqGridState {
  categories: IFAQCategory[];
  expandFaqPost: IFAQPost | undefined;
}

const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    Position.RELATIVE,
    Margin.MD_MT_8,
  ),
  faqGridHeadingMobile: (mobile: boolean | undefined) => classNames(
    Width.W_FULL,
    mobile ? BorderColor.BORDER_NEUTRAL_3 : null,
    mobile ? BorderWidth.BORDER_B_1 : null,
    mobile ? BorderStyle.BORDER_SOLID : null,
    mobile ? BackgroundColor.BG_NEUTRAL_1 : null,
    TextAlign.TEXT_CENTER,
    FontSize.TEXT_3XL,
    FontWeight.FONT_LIGHT,
    FontFamily.FONT_TITLE_2,
    Padding.P_6,
  ),
  faqGridBlockMobile: classNames(
    BorderColor.BORDER_NEUTRAL_3,
    BorderWidth.BORDER_B_1,
    BorderStyle.BORDER_SOLID,
    BackgroundColor.BG_NEUTRAL_1,
  ),
  grid: classNames(
    'kontaktsider--faq-grid',
    BorderColor.BORDER_NEUTRAL_3,
    BorderWidth.BORDER_L_1,
    BorderStyle.BORDER_SOLID,
  ),
  gridContainer: classNames(
    'kontaktsider--faq-grid--container',
  ),
  faqPostsContainer: (mobile: boolean | undefined) => classNames(
    'kontaktsider--faq-posts--container',
    BorderColor.BORDER_NEUTRAL_3,
    mobile ? null : BorderWidth.BORDER_R_1,
    mobile ? null : BorderWidth.BORDER_L_1,
    BorderStyle.BORDER_SOLID,
  ),
};

class FaqGrid extends React.PureComponent<IFaqGridProps, IFaqGridState> {

  private containerRef;

  constructor(props: IFaqGridProps) {
    super(props);
    this.containerRef = React.createRef();
    this.state = {
      categories: props.faq.categories || [],
      expandFaqPost: undefined
    };
  }

  public componentDidUpdate(prevProps: IFaqGridProps) {
    const {faqContext} = this.props;
    if (prevProps.faqContext.state.selectedCategory === undefined
      && prevProps.faq !== this.props.faq
      || prevProps.faqContext.state !== this.props.faqContext.state
      && prevProps.faq !== this.props.faq) {
      const urlParam = window.location.search.replace('?id=', '').split('&')[0];
      if (urlParam) {
        this.getCategoryPostFromUrl(Number(urlParam));
      }
    }
    if (prevProps.faqContext.state.selectedPostId !== faqContext.state.selectedPostId) {
      const url = window.location.search.replace('?id=', '').split('&')[0];
      if (url) {
        this.getCategoryPostFromUrl(Number(url));
      }
    }
    if (prevProps.faq !== this.props.faq) {
      this.setState({
        categories: this.filterCategories()
      });
    }
    // Scroll to Anchor
    if (document) {
      const url = document.location.href;
      const id = url.includes("#faq") ? 'faq' : url.includes("#kontakt") ? 'kontakt' : ''
      const scrollToAnchor = () => {
        const anchorTarget = document.getElementById(id);
        anchorTarget && anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      if (id !== '') {
        scrollToAnchor();
      }
    }
  }

  private getCategoryPostFromUrl(postId: number) {
    const {faq, faqContext} = this.props;
    const getPost = faq.posts.filter((post: IFAQPost) => post.id === postId);
    if (getPost.length > 0) {
      const getPosts = faq.posts.filter((post: IFAQPost) => post.taxonomy_id === getPost[0].taxonomy_id);
      const getCategory = faq.categories.filter((category: IFAQCategory) => category.taxonomy_id === getPost[0].taxonomy_id);
      faqContext.actions.setSelectedCategory(getCategory[0]);
      faqContext.actions.setSelectedPost(getPost[0]);
      faqContext.actions.setSelectedCategoryPosts(getPosts);
      setTimeout(() => {
        const container = ReactDOM.findDOMNode(this.containerRef.current) as HTMLElement;
        if (container) {
          const faqElement: HTMLElement | null = document.querySelector(`.faq-post-${getPost[0].id}`);
          if (faqElement) {
            scrollToTarget(faqElement, 62);
          } else {
            scrollToTarget(container, 62);
          }
        }
      }, 300)
    }
  }

  private filterCategories() {
    const {faq} = this.props;
    return faq.categories.filter((cat: IFAQCategory) => cat.count !== 0);
  }

  private onFaqCategoryClick = (category: IFAQCategory) => {
    const {faq, faqContext} = this.props;

    if (faqContext.state.selectedCategory === category) {
      this.closeCategoryPosts();
    } else {
      if (category.name !== "") {
        const container = ReactDOM.findDOMNode(this.containerRef.current) as HTMLElement;
        const filterFaqPosts = faq.posts.filter((post: IFAQPost) => post.taxonomy_id === category.taxonomy_id);
        faqContext.actions.setSelectedCategory(category);
        faqContext.actions.setSelectedCategoryPosts(filterFaqPosts);
        if (container) {
          scrollToTarget(container, 62);
        }
      }
    }
  };

  private closeCategoryPosts = () => {
    const {faqContext} = this.props;
    faqContext.actions.setSelectedCategory(undefined);
    faqContext.actions.setSelectedPost(undefined);
    this.setState({
      expandFaqPost: undefined
    })
  };

  private renderLoader = () => {
    return(
      <div className={classNames(TextAlign.TEXT_CENTER, Margin.MY_8)}>
        <Loader text={"Laster"}/>
      </div>
    )
  };


  private renderFaqGrid = () => {
    const {mobile} = this.props;
    const {categories} = this.state;
    const {
      selectedCategory,
      selectedPost,
      selectedFaqCategoryPosts
    } = this.props.faqContext.state;

    let i: number;
    let j: number;
    const chunk = 3;
    let tempArray;
    const faqGridSections: IFAQCategory[][] = [];

    if(categories.length === 0) {
      return this.renderLoader();
    }

    if (mobile) {
     return categories.map((category: IFAQCategory, key: number) => {
       return (
         <div className={styleClass.gridContainer} key={`faq-grid-block-${key}`}>
           <div className={styleClass.faqGridBlockMobile}>
             <FaqGridBlock
               category={category}
               selectedCategory={selectedCategory}
               onFaqCategoryClick={this.onFaqCategoryClick}
             />
           </div>

           {selectedFaqCategoryPosts.length > 0 && selectedCategory !== undefined && category === selectedCategory ?
             <div className={styleClass.faqPostsContainer (mobile)}>
               <FaqList
                 faqPosts={selectedFaqCategoryPosts}
                 postToExpand={selectedPost}
               />
             </div> : null}
         </div>
       )
      })
    } else {
      for (i = 0, j = categories.length; i < j; i += chunk) {
        tempArray = categories.slice(i, i + chunk);
        faqGridSections.push(tempArray);
      }

      return faqGridSections.map((categorySection: IFAQCategory[], key: number) => {
        return (
          <div className={styleClass.gridContainer} key={key}>
            <Grid composition={GridComposition.ALPHA} gutter={GridGutter.NONE} className={styleClass.grid}>
              {categorySection.map((category: IFAQCategory, innerKey: number) => {
                return (
                  <FaqGridBlock
                    key={`faq-grid-block-${innerKey}`}
                    category={category}
                    selectedCategory={selectedCategory}
                    onFaqCategoryClick={this.onFaqCategoryClick}
                  />
                )
              })}
            </Grid>

            {selectedFaqCategoryPosts.length > 0 && selectedCategory !== undefined && categorySection.indexOf(selectedCategory) !== -1 ?
              <div className={styleClass.faqPostsContainer(mobile)}>
                <FaqList
                  faqPosts={selectedFaqCategoryPosts}
                  postToExpand={selectedPost}
                />
              </div> : null}
          </div>
        )
      })
    }
  };



  public render() {
    const {mobile} = this.props;

    return (
      <div className={styleClass.root} ref={this.containerRef}>
        <Container>
          <Heading className={styleClass.faqGridHeadingMobile(mobile)} level={HeadingLevel.THREE}>
            Spørsmål og svar
          </Heading>
          {this.renderFaqGrid()}
        </Container>
      </div>
    )
  }
}

export default withFaqGridContext(withResponsive(withWPContext(FaqGrid)));
