import React from "react";
import {ISpidContext as ISpid, ISpidOptions, SpidContextConsumer} from "./SpidContextProvider";

export interface ISpidContext {
  login: () => void;
  logout: () => void;
  checkLoginStatus: () => void;
  spidOptions: ISpidOptions,
}

export interface IWithSpidContext {
  spidContext: ISpid,
}

export const withSpidContext = <P extends IWithSpidContext>(Component: any) =>
  class WithSpidContext extends React.PureComponent<any> {
    public render() {
      return (
        <SpidContextConsumer>
          {
            (spidContext: any) => <Component
              {...this.props}
              spidContext={spidContext}
            />
          }
        </SpidContextConsumer>
      );
    }
  };
