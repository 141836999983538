import React from "react";
import {
  IChat,
  IFAQ,
  IFeedbackCategories,
  IIncident,
  IContact,
  IFooterMenu,
  IContactFormMapping, IMaintenance
} from "../services/WPService";
import {IWPActions, IWPContext, WPConsumer} from "./WPContext";


export interface IWithWPContext {
	faq: IFAQ,
	incidents: IIncident[],
  incidentsLoaded: boolean;
	chat: IChat,
	feedbackCategories: IFeedbackCategories[],
	actions: IWPActions,
	contact: IContact,
	footerMenu: IFooterMenu[],
	contactFormMapping: IContactFormMapping[]
  maintenance: IMaintenance;
}

export const withWPContext = <P extends IWithWPContext>(Component: any) =>
    class WithWPContext extends React.PureComponent<any> {
        public render() {
            return (
                <WPConsumer>
                    {
                        (wpContext: IWPContext) => <Component
                            {...this.props}
                            faq={wpContext.state.faq}
                            incidents={wpContext.state.incidents}
                            incidentsLoaded={wpContext.state.incidentsLoaded}
														chat={wpContext.state.chat}
														feedbackCategories={wpContext.state.feedbackCategories}
														actions={wpContext.actions}
														contact={wpContext.state.contact}
														footerMenu={wpContext.state.footerMenu}
														contactFormMapping={wpContext.state.contactFormMapping}
                            maintenance={wpContext.state.maintenance}
                        />
                    }
                </WPConsumer>
            );
        }
    };
