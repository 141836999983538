import * as React from "react";
import MobileDetect from "mobile-detect";
import {IWithResponsiveProps, withResponsiveBrowserOnly} from "@snoam/pinata";
import {useContext} from "react";

export const isHermes = (userAgent) => /hermes/i.test(userAgent);
export const isIOS = (userAgent) => /(i[PSa-z\s]+);.*?CPU\s([OSPa-z\s]+(?:([\d_]+)|;))/i.test(userAgent);
export const isAndroid = (userAgent) => /android/ig.test(userAgent);
export const isIe = (userAgent) => /(?:\b(MS)?IE\s+|\bTrident\/7\.0;.*\s+rv:)(\d+)/ig.test(userAgent); // Ref: https://stackoverflow.com/a/17447718

export const ResponsiveContext = React.createContext<{
  mobile: boolean;
  desktop: boolean;
  tablet: boolean;
  landscape?: boolean | undefined;
  portrait?: boolean | undefined;
  isHermes?: boolean | undefined;
  ios?: boolean | undefined;
  android?: boolean | undefined;
  isIe?: boolean | undefined;
}>({
  mobile: false,
  tablet: false,
  desktop: false,
  landscape: undefined,
  portrait: undefined,
  isHermes: undefined,
  ios: undefined,
  android: undefined,
  isIe: undefined,
});

const ServerResponsiveContextProvider: React.FunctionComponent<{ userAgent: string }> = ({children, userAgent = 'unknown'}) => {
  const md = new MobileDetect(userAgent);
  const state = {
    mobile: Boolean(md.mobile()),
    tablet: Boolean(md.tablet()),
    desktop: Boolean(!md.tablet() && !md.phone()),
    isHermes: isHermes(userAgent),
    ios: isIOS(userAgent),
    android: isAndroid(userAgent),
    isIe: isIe(userAgent),
  };
  return (
    <ResponsiveContext.Provider value={state}>
      {children}
    </ResponsiveContext.Provider>
  )
};

const ClientResponsiveContextProvider = withResponsiveBrowserOnly<{userAgent: string} & IWithResponsiveProps>(({children, mobile, desktop, landscape, portrait, tablet, userAgent}) => {
  const state = {
    mobile: Boolean(mobile),
    tablet: Boolean(tablet),
    desktop: Boolean(desktop),
    landscape,
    portrait,
    isHermes: isHermes(userAgent),
    ios: isIOS(userAgent),
    android: isAndroid(userAgent),
    isIe: isIe(userAgent)
  };
  return (
    <ResponsiveContext.Provider value={state}>
      {children}
    </ResponsiveContext.Provider>
  )
});

export const ResponsiveContextProvider: React.FunctionComponent<{ userAgent: string }> = (props) => {

  const isSSR = typeof window === "undefined";

  if (isSSR) {
    return <ServerResponsiveContextProvider  {...props}/>
  }

  return <ClientResponsiveContextProvider {...props}/>
};

export const useResponsiveContext = ()=> {
  return useContext(ResponsiveContext);
};
