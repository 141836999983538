import * as React from 'react';
import {Component, createContext} from 'react';
import {clientName as findClientName, ClientShortHandName, clientShortHandNameFromHostname, mainHostnameFromHostname} from '@snoam/mono-scc';

const debug = require('debug')('kontaktsider:client:clientContext');

export interface IClientContextProps {
  href: string,
  domain: string
}

export interface IClientContext {
  clientShortHandName: ClientShortHandName;
  hostname: string;
  href: string;
  clientName: string;
}

export const ClientContext = createContext<IClientContext>(
  {clientName: '', clientShortHandName: ClientShortHandName.SCH, hostname: '', href: ''} as IClientContext);
export const ClientContextConsumer = ClientContext.Consumer;

class ClientContextProvider extends Component<IClientContextProps, {}> {

  public render() {
    const { domain, href } = this.props;
    const clientShortHandName: ClientShortHandName = clientShortHandNameFromHostname(domain);
    const clientName = findClientName(clientShortHandName);
    const hostname: string = mainHostnameFromHostname(domain);
    const clientContext = {
      clientName,
      clientShortHandName,
      hostname,
      href,
    };
    debug('ClientContext(%o): %o', { ...clientContext, domain });

    return (
      <ClientContext.Provider value={{ ...clientContext }}>
        {this.props.children}
      </ClientContext.Provider>
    );
  }
}

export default ClientContextProvider;
