import * as React from 'react';
import {
  AlignItems,
  Appearance,
  BackgroundColor,
  classNames,
  Display,
  FontFamily,
  FontWeight,
  Icon,
  IconName,
  IconSize,
  Margin,
  Padding,
  PointerEvents,
  Position,
  TextColor,
  Width
} from '@snoam/pinata';
import uniqueId from "lodash/uniqueId";

export interface ISelectProps {
	label?: string;
	errorMsg?: string;
	validMsg?: boolean | string;
	className?: string;
	value?: any;
	onChange?: any;
	options: any;
}

export interface ISelectState {
	id: string;
	value: string | number | string[];
}

const styleClass = {
	root: classNames(
		'kontaktsider-select',
		Appearance.APPEARANCE_NONE,
		Width.W_FULL,
		BackgroundColor.BG_NEUTRAL_1,
		TextColor.TEXT_NEUTRAL_6,
		Padding.P_3,
		FontFamily.FONT_BODY,
		FontWeight.FONT_LIGHT,
	),
	container: classNames(
		'kontaktsider-select--container',
		Margin.MB_1,
	),
	label: 'kontaktsider-select-label',
	noLabel: 'kontaktsider-select-label--hide',
	errorMsg: 'kontaktsider-select__error-msg',
	errorMsgShow: 'kontaktsider-select__error-msg--show',
	valid: 'kontaktsider-select--valid',
	invalid: 'kontaktsider-select--invalid',
	hasValue: 'kontaktsider-select--has-value',
	icon: classNames(
		PointerEvents.POINTER_EVENTS_NONE,
		Position.ABSOLUTE,
		Position.TOP_0,
		Position.RIGHT_0,
		Display.FLEX,
		AlignItems.ITEMS_CENTER,
		Margin.MR_4,
		Margin.MT_2,
		FontWeight.FONT_SEMIBOLD,
	),
};

export default class Select extends React.PureComponent<ISelectProps, ISelectState> {


	constructor(props) {
		super(props);

		this.state = {
			id: uniqueId('select_'),
			value: props.value ? props.value : '',
		};
	}

	public componentDidUpdate(prevProps: ISelectProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				value: this.props.value ? this.props.value : '',
			})
		}
	}

	private onChange = (e) => {
		e.preventDefault();

		const {onChange} = this.props;

		if (onChange) {
			onChange(e);
		}

		this.setState({value: e.target.value});
	};

	private renderOptions = (options) => {
		return options.map(option =>
			<option value={option} key={uniqueId('option-')}>{option}</option>
		)
	};

	public render() {
		const {className, label, errorMsg, validMsg, options, ...rest} = this.props;
		const {id, value} = this.state;

		const hasError = !!errorMsg;
		const hasValue = typeof value === 'number' || (typeof value === 'string' && value);

		const errMsgClasses = classNames(
			{
				[styleClass.errorMsg]: hasError,
				[styleClass.errorMsgShow]: hasError
			},
		);

		const containerClasses = classNames(
			styleClass.container,
			{
				[styleClass.hasValue]: hasValue,
				[styleClass.valid]: validMsg && !hasError,
				[styleClass.invalid]: hasError,
				[styleClass.noLabel]: !label
			},
			className
		);

		return (
			<div className={containerClasses}>
				{label ? <label
					htmlFor={id}
					className={styleClass.label}>
					{label}
				</label> : null}
				<div className={classNames(Position.RELATIVE, BackgroundColor.BG_WHITE, TextColor.TEXT_NEUTRAL_6)}>
					<select
						{...rest}
						id={id}
						value={value}
						className={styleClass.root}
						onChange={this.onChange}
					>
						{this.renderOptions(options)}
					</select>
					<div className={styleClass.icon}>
						<Icon name={IconName.ARROW_DROP_DOWN} size={IconSize.BASE}/>
					</div>
				</div>
				{errorMsg && <span className={errMsgClasses}>{errorMsg}</span>}
			</div>
		);
	}
}
