import React from "react";

import {getInstance, Pulse} from "@snoam/mono-pulse";


export interface IWithPulseEvent {
  pulse: Pulse;
}

export const withPulseEvent = <P extends IWithPulseEvent>(Component: any) =>
  class WithPulseEvent extends React.PureComponent<any> {
    render() {
      const pulseInstance = getInstance();
      return (
        <Component
          {...this.props}
          pulse={pulseInstance}
          />
      )
    }
  };
