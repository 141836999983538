import React from 'react';
import { IFAQCategory, IFAQPost } from '../services/WPService';

export interface IFaqGridContextState {
  selectedCategory: IFAQCategory | undefined;
  selectedFaqCategoryPosts: IFAQPost[];
  selectedPost: IFAQPost | undefined;
  selectedPostId: number | undefined;
};

export interface IFaqGridContextActions {
  setSelectedCategory: (category: IFAQCategory | undefined) => void;
  setSelectedPost: (post: IFAQPost | undefined) => void;
  setSelectedCategoryPosts: (selectedFaqCategoryPosts: IFAQPost[]) => void;
  setSelectedPostId: (selectePostId: number | undefined) => void;
}

export interface IFaqGridContext {
  actions: IFaqGridContextActions;
  state: IFaqGridContextState;
}

const FaqGridContext = React.createContext<IFaqGridContext>({
  state: {} as IFaqGridContextState,
  actions: {} as IFaqGridContextActions
});

export const FaqGridConsumer = FaqGridContext.Consumer;

export default class FaqGridProvider extends React.Component<any, IFaqGridContextState> {
    constructor(props: any) {
      super(props);
      this.state = {
        selectedCategory: undefined,
        selectedFaqCategoryPosts: [],
        selectedPost: undefined,
        selectedPostId: undefined
      };
    }

    private setSelectedCategory = (selectedCategory: IFAQCategory | undefined) => {
      this.setState({
        selectedCategory
      });
    }

    private setSelectedPost = (selectedPost: IFAQPost | undefined) => {
        this.setState({
          selectedPost
        });
    }

    private setSelectedPostId = (selectedPostId: number | undefined) => {
      this.setState({
        selectedPostId
      })
    }

    private setSelectedCategoryPosts = (selectedFaqCategoryPosts: IFAQPost[]) => {
      this.setState({selectedFaqCategoryPosts});
    }

    public render() {
      const {state} = this;

      const actions = {
        setSelectedCategory: this.setSelectedCategory,
        setSelectedPost: this.setSelectedPost,
        setSelectedCategoryPosts: this.setSelectedCategoryPosts,
        setSelectedPostId: this.setSelectedPostId
      };

      const context = {
        actions,
        state
      };

      return <FaqGridContext.Provider value={context}>
        {this.props.children}
      </FaqGridContext.Provider>
    }
}
