import * as React from 'react';
import {IIncident} from "../../services/WPService";
import {
  BorderColor,
  BorderStyle,
  BorderWidth,
  Button,
  ButtonSize,
  ButtonSkin,
  classNames,
  Cursor,
  Display,
  FontFamily,
  FontWeight,
  IWithResponsiveProps,
  Margin,
  MinHeight,
  Padding,
  TextAlign,
  TextColor,
  FontSize,
  Width,
  AlignItems,
  FlexDirection,
  JustifyContent,
  FlexShrink,
  FlexGrow,
  BackgroundColor,
  MaxWidth
} from "@snoam/pinata";
import ReactHtmlParser from 'react-html-parser';
import {trackEngagement} from "../pulse/Events";
import {Modal, ModalContext} from '@snoam/mono-modal';
import {useContext} from 'react';
import {useResponsiveContext} from '../../context/ResponsiveContext';

const styleClass = {
  item: (mobile) => classNames(
    'kontaktsider--incident',
    FlexShrink.FLEX_SHRINK_0,
    FlexGrow.FLEX_GROW_0,
    Display.FLEX,
    Margin.MD_MY_4,
    Padding.P_4,
    FontFamily.FONT_TITLE_2,
    TextColor.TEXT_WHITE,
    FontSize.TEXT_BASE,
    FontWeight.FONT_NORMAL,
    Cursor.CURSOR_POINTER,
    Width.LG_W_1_4,
    BorderStyle.BORDER_SOLID,
    BorderColor.BORDER_NEUTRAL_4,
    AlignItems.ITEMS_CENTER,
    {
      [FlexDirection.FLEX_ROW]: mobile,
      [FlexDirection.FLEX_COL]: !mobile,
      [BorderWidth.BORDER_B_1]: mobile,
      [BorderWidth.BORDER_R_1]: !mobile,
      [Padding.PX_4]: !mobile,
      [JustifyContent.JUSTIFY_CENTER]: !mobile,
      [MinHeight.MIN_H_48]: !mobile,
    },
  ),
  icon: (mobile) => classNames(
    Width.MD_W_10,
    Width.XS_W_8,
    Display.BLOCK,
    Margin.MR_4,
    Margin.MD_MX_AUTO,
    {[Margin.MB_4]: !mobile},
  ),
  title: classNames(
    TextAlign.MD_TEXT_CENTER,
    Width.W_FULL,
    FontWeight.FONT_BOLD,
  ),
  modal: {
    outer: classNames(
      BackgroundColor.BG_NEUTRAL_1,
    ),
    container: classNames(
      TextAlign.TEXT_CENTER,
      Padding.PX_8,
      Margin.MB_6,
      Width.W_XL,
      MaxWidth.MAX_W_FULL,
    ),
    icon: classNames(
      Width.W_16,
      Padding.PB_4,
      Margin.MX_AUTO,
    ),
    title: classNames(
      FontSize.TEXT_BASE,
      TextColor.TEXT_PRIMARY_DARK,
      Padding.PB_2,
      FontFamily.FONT_TITLE_1
    ),
  }
};

export interface IIncidentProps extends IIncident, IWithResponsiveProps {
  title: string;
  icon_link: string;
  content: string;
  id: string;
}

const Incident: React.FC<IIncidentProps> = ({title, icon_link, content, id}) => {

  const incidentModalId = `${id}-modal`;
  const {closeModal, openModal, openModalId} = useContext(ModalContext);
  const { mobile } = useResponsiveContext();
  const icon = icon_link.includes('original') ? icon_link.replace('original', 'white') : icon_link;

  const onModalChange = () => {
    if(openModalId === incidentModalId) {
      return;
    }

    trackEngagement(`Click on incident [${title}]`, 'incident');
    openModal((incidentModalId));
  };

  const onKeyDown = (event, callback) => {
    return event.key === 'Enter' || event.key === ' ' ? callback() : null;
  };

  const renderModal = () => {
    const icon = icon_link.includes('white') ? icon_link.replace('white', 'original') : icon_link;

    return (
      <Modal
        id={incidentModalId}
        onClose={closeModal}
        backdrop={true}
        className={styleClass.modal.outer}
      >
        <div className={styleClass.modal.container}>
          <img src={icon} className={styleClass.modal.icon} alt={"Illustrativt bilde"}/>
          <h1 className={styleClass.modal.title} tabIndex={0}>{title}</h1>
          <div tabIndex={0}>{ReactHtmlParser(content)}</div>

          <Button
            skin={ButtonSkin.SECONDARY}
            size={ButtonSize.SMALL}
            className={classNames(Margin.MB_0, Margin.MT_8)}
            onClick={(e) => closeModal()}
            onKeyDown={(e) => onKeyDown(e, closeModal)}
            text={"Ok, lukk"}
          />
        </div>
      </Modal>
    );
  };

  return (
    <>
      <div
        className={styleClass.item(mobile)}
        tabIndex={0}
        onKeyDown={(e) => onKeyDown(e, onModalChange)}
        onClick={onModalChange}
      >
        <img src={icon} alt={"Mobil ikon"} className={styleClass.icon(mobile)}/>
        <p className={styleClass.title}>{title}</p>
      </div>
      {renderModal()}
    </>
  )
}

export default Incident;
