import * as React from 'react';
import {IFAQCategory} from '../../services/WPService';
import {
  classNames,
  Width,
  Display,
  Height,
  BackgroundColor,
  BorderStyle,
  BorderColor,
  BorderWidth,
  TextColor,
  FontWeight,
  Cursor,
  withResponsive,
  IWithResponsiveProps,
  TextAlign,
  Padding, JustifyContent, AlignItems, FontSize, LineHeight, FontFamily,
} from '@snoam/pinata';
import {trackEngagement} from "../pulse/Events";

interface IFaqGridBlockProps extends IWithResponsiveProps {
  category: IFAQCategory;
  selectedCategory: IFAQCategory | undefined;
  onFaqCategoryClick: (category: IFAQCategory) => void;
}

const styleClass = {
  root: (mobile: boolean | undefined) => classNames(
    Display.MD_FLEX,
    JustifyContent.JUSTIFY_CENTER,
    AlignItems.ITEMS_CENTER,
    BorderColor.BORDER_NEUTRAL_3,
    mobile ? null : BorderWidth.BORDER_B_1,
    mobile ? null : BorderWidth.BORDER_R_1,
    BorderStyle.BORDER_SOLID,
    'kontaktsider--faq-grid-block',
  ),
  cardRoot: (name: string, isSelected: boolean | undefined, mobile: boolean | undefined) => classNames(
    name === "" ? BackgroundColor.BG_WHITE : null,
    isSelected ? BackgroundColor.BG_PRIMARY_3 : BackgroundColor.BG_WHITE,
    isSelected ? TextColor.TEXT_WHITE : TextColor.TEXT_PRIMARY_4,
    isSelected ? BackgroundColor.FOCUS_BG_PRIMARY_3 : BackgroundColor.FOCUS_BG_NEUTRAL_2,
    Display.FLEX,
    Width.W_FULL,
    JustifyContent.JUSTIFY_CENTER,
    AlignItems.ITEMS_CENTER,
    mobile ? Height.H_16 : Height.H_24,
    TextAlign.TEXT_CENTER,
    Padding.P_4,
    FontSize.TEXT_XL,
    FontWeight.FONT_NORMAL,
    LineHeight.LEADING_NORMAL,
    FontFamily.FONT_TITLE_2,
    Cursor.CURSOR_POINTER,
    'kontaktsider--faq-grid-block--card',
  )
};


class FaqGridBlock extends React.PureComponent<IFaqGridBlockProps> {
  private onItemClick = (e: any) => {
    this.props.onFaqCategoryClick(this.props.category);
    trackEngagement(`Click on main category [${this.props.category.name}]`, 'mainCategory');
  };

  private onKeyClick = (e: any) => {
    if (e.key === "Enter") {
      this.props.onFaqCategoryClick(this.props.category);
      trackEngagement(`Click on main category [${this.props.category.name}]`, 'mainCategory');
    }
  };

  public render() {
    const {category, selectedCategory, mobile} = this.props;
    const isSelected = selectedCategory && category.slug === selectedCategory.slug ? true : false;
    return (
      <div
        className={styleClass.root(mobile)}
        onClick={this.onItemClick}
        onKeyDown={this.onKeyClick}
      >
        <div className={styleClass.cardRoot(category.name, isSelected, mobile)} tabIndex={0}>
          {category.name}
        </div>
      </div>
    )
  }
}

export default withResponsive(FaqGridBlock);
