import * as React from 'react';
import {Helmet} from 'react-helmet';
import Footer from './components/Footer/Footer';
import Body from './components/Body/Body';
import Header from './components/Header/Header'
import ClientContextProvider, {
  ClientContextConsumer,
  IClientContext,
} from './context/ClientContextProvider';
import {IThemeContext, ThemeConsumer, ThemeProvider} from './context/ThemeContext';
import WPProvider from "./context/WPContext";
import {Sprite} from "./sprite.symbol";
import FaqGridProvider from './context/FaqGridContextProviver';
import PageViewEvent from "./components/pulse/PageViewEvent";
import SpidContextProvider from "./context/SpidContextProvider";


import {
  BackgroundColor,
  JumpToContent
} from '@snoam/pinata';
import SubscriptionsContextProvider from './context/SubscriptionsContextProvider';
import {ModalProvider} from '@snoam/mono-modal';
import {ResponsiveContextProvider} from './context/ResponsiveContext';

export interface IAppProps {
  domain: string;
  href: string;
  reload?: (forcedReload?: boolean) => void;
  userAgent: string;
}

export const contextPath = '/kundeservice';

function App(props: IAppProps) {
  const {userAgent, domain, href} = props;

  const skipHeaderAndFooter = /hermes/i.test(userAgent);
  return (
    <div className="App">
      <ClientContextProvider
        domain={domain}
        href={href}
      >
        <ResponsiveContextProvider userAgent={userAgent}>
          <ModalProvider>
            <SpidContextProvider {...props}>
              <ClientContextConsumer>{(clientContext: IClientContext) => (
                <React.Fragment>
                  <PageViewEvent pageId={'kundeservice'} key={'page_view_event'}
                                 eventLabel={'View kundeservice page'}/>
                  <ThemeProvider clientShortHandName={clientContext.clientShortHandName}>
                    <ThemeConsumer>{(theme: IThemeContext) => (
                      <Helmet>
                        {!(window as any).ISSSR &&
                        <link href={`${contextPath}/assets/css/${theme.themeCss}`} rel="stylesheet"/>}
                        <link href={`${contextPath}/assets/css/kontaktsider.css`} rel="stylesheet"/>
                        <link rel="shortcut icon"
                              href={`${contextPath}/favicons/favicon-${clientContext.clientShortHandName.toLowerCase()}.ico`}/>
                        <title>{clientContext.clientName} | Kundeservice</title>
                      </Helmet>
                    )}
                    </ThemeConsumer>
                  </ThemeProvider>
                  <WPProvider hostname={clientContext.hostname}>
                    <FaqGridProvider>
                      <JumpToContent>
                        {!skipHeaderAndFooter &&
                        <Header
                          clientName={clientContext.clientName}
                          clientShortHandName={clientContext.clientShortHandName}
                          hostname={clientContext.hostname}/>
                        }
                      </JumpToContent>
                      <main className={
                        BackgroundColor.BG_NEUTRAL_1}>
                        <SubscriptionsContextProvider>
                          <Body clientShortHandName={clientContext.clientShortHandName}/>
                        </SubscriptionsContextProvider>
                      </main>
                      {!skipHeaderAndFooter &&
                      <Footer
                        hostname={clientContext.hostname}
                        clientShorthandName={clientContext.clientShortHandName}
                        clientName={clientContext.clientName}/>
                      }
                      <Sprite/>
                    </FaqGridProvider>
                  </WPProvider>
                </React.Fragment>
              )}
              </ClientContextConsumer>
            </SpidContextProvider>
          </ModalProvider>
        </ResponsiveContextProvider>
      </ClientContextProvider>
    </div>
  )
}

export default App;
