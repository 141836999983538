import * as React from 'react';
import {
  BorderColor,
  BorderStyle,
  BorderWidth,
  Button,
  ButtonSize,
  ButtonSkin,
  classNames,
  Display,
  FontFamily,
  FontWeight,
  Height,
  Margin,
  Padding,
  Resize,
  TextColor,
  FontSize,
  Width, FlexDirection, LineHeight
} from '@snoam/pinata';
import ReactHtmlParser from 'react-html-parser';
import {IWithSpidContext, withSpidContext} from '../../context/withSpidContext';
import {withWPContext} from '../../context/withWPContext';
import {IFAQPost} from '../../services/WPService';
import {trackEngagement} from '../pulse/Events';
import {convertLinksToPinataStyle} from '../../utils';
import { IWPActions } from '../../context/WPContext';

interface IFaqListItemState {
  feedbackSuccess: boolean;
  isLoading: boolean;
  feedback: string;
  error: boolean;
}

export interface IFaqListItemProps extends IWithSpidContext {
  actions: Pick<IWPActions, 'sendFeedback'>,
  post: IFAQPost;
}

const styleClass = {
  accordionBody: classNames(
    Width.MD_W_2_3,
    Width.W_FULL,
    Display.FLEX,
    FlexDirection.FLEX_COL,
    FontSize.TEXT_BASE,
    TextColor.TEXT_NEUTRAL_6,
    LineHeight.LEADING_NORMAL,
    Padding.PL_6,
    Margin.ML_1,
  ),
  accordionActionBody: classNames(
    Margin.MT_8,
    Display.FLEX,
    FlexDirection.FLEX_COL
  ),
  accordionActionButtons: classNames(
    Display.FLEX,
    FlexDirection.FLEX_ROW
  ),
  feedbackTextarea: classNames(
    BorderWidth.BORDER_1,
    BorderStyle.BORDER_SOLID,
    BorderColor.BORDER_NEUTRAL_2,
    Height.H_40,
    Resize.RESIZE_NONE,
    FontSize.TEXT_SM,
  ),
  feedbackHelpText: classNames(
    FontSize.TEXT_SM,
    Margin.MB_2,
  ),
};


export class FaqListItem extends React.PureComponent<IFaqListItemProps, IFaqListItemState> {

  constructor(props) {
    super(props);

    this.state = {
      feedbackSuccess: false,
      isLoading: false,
      feedback: '',
      error: false,
    }
  }

  componentDidMount() {
    trackEngagement(`Click on sub category [${this.props.post.title}]`, 'subCategory');
  }

  private sendFeedback = () => {
    this.setState({isLoading: true});

    const spid = this.props.spidContext.state.userId;
    const sig = this.props.spidContext.state.sig;

    trackEngagement(`Form submitted [${this.props.post.title}]`, 'satisfaction_form');

    const {post} = this.props;
    const mainCategory = post.mainCategory;
    this.props.actions.sendFeedback(spid, sig, mainCategory, post.subCategory, this.state.feedback)
      .then(res => {
        this.setState({feedbackSuccess: true})
      })
      .catch(e => this.setState({error: true}));

    this.setState({isLoading: false});
  };

  private showFeedback = () => {
    this.setState(prevState => ({
      feedbackSuccess: !prevState.feedbackSuccess
    }));
    trackEngagement(`Click on feedback [${this.props.post.title}]`, 'satisfied');
  };

  private showFeedbackUnsatisfied = () => {
    this.setState(prevState => ({
      feedbackSuccess: !prevState.feedbackSuccess
    }));
    trackEngagement(`Click on feedback [${this.props.post.title}]`, 'unsatisfied');
  };

  private renderFeedbackSuccess = () => {
    return (
      <React.Fragment>
        Takk for din tilbakemelding!
      </React.Fragment>
    )
  };

  private handleChange = (event) => {
    this.setState({feedback: event.target.value})
  };

  public render() {

    const {post} = this.props;
    const {feedbackSuccess} = this.state;
    const {userId} = this.props.spidContext.state;

    return (
      <div className={styleClass.accordionBody}>
        <div className={FontFamily.FONT_TITLE_2}>
          {ReactHtmlParser(post.content, {decodeEntities: true, transform: convertLinksToPinataStyle})}
        </div>
        <div className={styleClass.accordionActionBody}>
            <React.Fragment>
              <p className={FontWeight.FONT_SEMIBOLD}>Fikk du svar på det du lurte på?</p>
              {!feedbackSuccess ?
                <div className={styleClass.accordionActionButtons}>
                <Button text={'Ja'} disabled={feedbackSuccess}
                        skin={feedbackSuccess ? ButtonSkin.PRIMARY : ButtonSkin.SECONDARY}
                        size={ButtonSize.SMALL} className={classNames(Margin.MR_2, FontWeight.FONT_NORMAL)} onClick={this.showFeedback} />
                <Button text={'Nei'} disabled={feedbackSuccess}
                        skin={ButtonSkin.SECONDARY} size={ButtonSize.SMALL}
                        onClick={this.showFeedbackUnsatisfied} className={FontWeight.FONT_NORMAL}  />
              </div> : null}
              {feedbackSuccess ? this.renderFeedbackSuccess() : null}
            </React.Fragment>
        </div>
      </div>
    )
  }
}

export default withSpidContext<any>(withWPContext<any>(FaqListItem));
