import {ClientShortHandName} from "@snoam/mono-scc";
import { convertNodeToElement } from 'react-html-parser';

export function buildApiUrl(hostname: string) {
    if(isTest() ) {
        return `https://test-kundeportal.${hostname.split('.')[0]}.no`;
    }
    return `https://kundeportal.${hostname}`;
}

export function getCoreHeaderStyleFromClientName(clientName: string) {
    if(clientName.toLowerCase() === 'bti') {
        return 'bt'
    }
    return clientName;
}

export function scrollToTarget(element: HTMLElement, offset: number){
	const elementPosition: number = element.getBoundingClientRect().top;
	const position: number = elementPosition - offset;
	window.scrollTo({
		top: position + window.pageYOffset,
		behavior: "smooth"
	});
	element.focus();
}

export function isTest() {
    return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname.includes('.lh') || window.location.hostname.includes('localhost') || window.location.hostname.includes('test-')
}

export function chatClientShorthandName(clientShorthandName: string) {
    const map = {
        'bti': 'bt',
        'sa': 'ab',
        'ap': 'ap',
    };
    return map[clientShorthandName];
}

export function convertLinksToPinataStyle(node, index) {
  if(node.type === 'tag' && node.name === 'a') {
    node.attribs.class = 'pinata-link';
    return convertNodeToElement(node, index, convertLinksToPinataStyle);
  }
}

export const coreHeaderSkin: (clientShortHandName: ClientShortHandName) => string | undefined = (clientShortHandName => ({
  [ClientShortHandName.SA]: 'sa',
  [ClientShortHandName.AP]: 'ap',
  [ClientShortHandName.ASK]: 'avavis',
  [ClientShortHandName.BTI]: 'bt',
  [ClientShortHandName.BYN]: 'bnytt',
  [ClientShortHandName.DP]: 'e24',
  [ClientShortHandName.STR]: 'stri',
  [ClientShortHandName.VNY]: 'vnytt',
  [ClientShortHandName.VG]: 'vg',
}[clientShortHandName]));

export const getUrsContactFormUrl: (clientShortHandName: ClientShortHandName) => string | undefined = ((clientShortHandName) => {
    return isTest()
        ? {
            [ClientShortHandName.SA]: 'https://sa.mypage.pre.schibsted.digital/minside/kontakt',
            [ClientShortHandName.E24]: 'https://stage.e24.no/minside/kontakt',
            [ClientShortHandName.DP]: 'https://stage.e24.no/minside/kontakt',
            [ClientShortHandName.BTI]:'https://bt.mypage.pre.schibsted.digital/minside/kontakt',
	    [ClientShortHandName.AP]:'https://ap.mypage.pre.schibsted.digital/minside/kontakt',
        }[clientShortHandName]
        : {
            [ClientShortHandName.SA]: 'https://aftenbladet.no/minside/kontakt',
            [ClientShortHandName.E24]: 'https://e24.no/minside/kontakt',
            [ClientShortHandName.DP]: 'https://e24.no/minside/kontakt',
            [ClientShortHandName.BTI]:'https://bt.no/minside/kontakt',
            [ClientShortHandName.AP]:'https://ap.no/minside/kontakt',
        }[clientShortHandName]
})
