import * as React from 'react';
import {Component, createContext} from 'react';
import {Identity, Monetization} from '@schibsted/account-sdk-browser';
import {ClientShortHandName, clientShortHandNameFromHostname, getClientId, mainHostnameFromHostname} from '@snoam/mono-scc';
import {platform as osPlatform} from 'os';
import {IAppProps} from '../App';
import {initialize, getInstance} from "@snoam/mono-pulse";


export const isProduction: (domain: string) => boolean = domain => !domain.match(/localhost|test|0\.0\.0\.0|\.lh$/);

const debug = require('debug')('kontaktsider:client:spidContext');

export type SpidContextProps = Pick<IAppProps, 'domain' | 'href' | 'reload'>;

export interface ISpidContextState {
  spidOptions: ISpidOptions;
  ready: boolean;
  user?: any;
}

export interface ISpidContext {
  actions: {
    login: () => void;
    logout: () => void;
    checkLoginStatus: () => void;
  };
  state: ISpidContextState & {
    givenName: string;
    spidOptions: ISpidOptions;
    userId: string;
    sig: string;
  };
}

export type SpidContextType = ISpidContext | undefined;

const SpidContext = createContext<SpidContextType>({} as SpidContextType);
export const SpidContextConsumer = SpidContext.Consumer;

const redirectUri: (isProd: boolean) => string = isProd => `https://us-central1-s-plat.cloudfunctions.net/redirect-helper-${isProd ? 'prod' : 'stage'}`;

export interface ISpidOptions {
  clientId: string;
  redirectUri: string;
  env: 'PRE' | 'PRO_NO';
  sessionDomain?: string;
  siteSpecificLogout?: boolean;
}

class SpidContextProvider extends Component<SpidContextProps, ISpidContextState> {

  private identity?: Identity;

  public login = () => {
    if (this.identity) {
      this.identity.login({state: encodeURIComponent(this.props.href)})
    }
  };

  public logout = async () => {
    if (this.identity) {
      await this.identity.logout();
      if (this.props.reload) {
        this.props.reload()
      }
    }
  };

  autoLogin = () => {
    debug("autoLogin()");
    if (!this.identity) {
      return;
    }
    this.identity.hasSession(true)
      .then((user) => {
        debug("autoLogin() OK", user);
        this.setState({user})
      })
      .catch(e => debug("autoLogin() rejected", e));
  };

  public checkLoginStatus = () => {
    if (this.identity) {
      const identity: Identity = this.identity;
      identity.isLoggedIn().then((loggedIn: boolean) => {
        debug("checkLoginStatus() loggedIn: ", loggedIn);
        if (loggedIn) {
          identity.getUser().then((user) => {
            this.setState({user});
            const pulse = getInstance();
            this.setState({ready: true});
            pulse.setUser(user.userId);
            let expiresIn = user.expiresIn;
            if (expiresIn > 21600) {
              expiresIn = 21600; //Not more than 15 days expiry to avoid max limit for setInterval()
            }
            setInterval(this.autoLogin, (expiresIn - 15) * 1000); //Autologin 15 seconds before expiry
          });
        } else {
          this.setState({ready: true});
        }
      })
    } else {
      this.setState({ready: true});
    }
  };

  instanceOfHasSessionSuccessResponse(object: any): any {
    return object && 'givenName' in object;
  }


  constructor(props: SpidContextProps, context: any) {
    super(props, context);

    this.state = {
      spidOptions: SpidContextProvider.spidOptions(props.domain),
      ready: false,
    };
  }

  static spidOptions(domain: string): ISpidOptions {
    const sessionDomain = `https://id${isProduction(domain) ? '' : '-pre'}.${mainHostnameFromHostname(domain)}`.replace(/\.lh$/, '.no');
    return {
      clientId: getClientId(clientShortHandNameFromHostname(domain), isProduction(domain)),
      redirectUri: redirectUri(isProduction(domain)),
      env: isProduction(domain) ? 'PRO_NO' : 'PRE',
      sessionDomain,
      siteSpecificLogout: (process.env.NODE_ENV !== "development"),
    };
  }

  componentDidUpdate() {
    if (this.instanceOfHasSessionSuccessResponse(this.state.user)) {
      const pulse = getInstance();
      pulse.setUser(this.state.user.userId);
    }
  }

  UNSAFE_componentWillMount() {
    const {domain} = this.props;

    if ((osPlatform() + '') === 'browser') {
      const spidOptions = SpidContextProvider.spidOptions(domain);

      debug('spidOptions: %o', spidOptions);

      try {
        this.identity = new Identity(spidOptions);
        (this.identity as any)._enableSessionCaching = false;
        (window as any).identity = this.identity;
        (window as any).monetization = new Monetization(spidOptions);
      } catch (e) {
        debug('spid init error: %o', e);
      }
      this.checkLoginStatus();
    } else {
      debug('Skipping SPiD init, this is not a browser environment...');
      this.setState({ready: true});
    }

    const clientShortHandName: ClientShortHandName = clientShortHandNameFromHostname(domain);
    initialize(clientShortHandName);
  }

  render() {
    const {state} = this;
    const actions = {
      checkLoginStatus: this.checkLoginStatus,
      login: this.login,
      logout: this.logout,
    };
    const context = {
      actions,
      state: Object.assign({}, state, {
        givenName: this.instanceOfHasSessionSuccessResponse(this.state.user) ? this.state.user.givenName : '',
        userId: this.instanceOfHasSessionSuccessResponse(this.state.user) ? this.state.user.userId : null,
        sig: this.instanceOfHasSessionSuccessResponse(this.state.user) ? this.state.user.sig : ''
      }),
    };
    return <SpidContext.Provider value={context}>
      {this.state.ready && this.props.children}
    </SpidContext.Provider>;
  }
}

export default SpidContextProvider;
