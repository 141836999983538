import {Moment} from "moment";
import {buildApiUrl} from "../utils";

const debug = require('debug')('kontaktsider:client:WPService');

export interface IFAQ {
  categories: IFAQCategory[],
  posts: IFAQPost[],
}

export interface IFAQCategory {
  name: string;
  taxonomy_id: number;
  count: number;
  slug: string;
}

export interface IFAQPost {
  id: number;
  title: string;
  content: string;
  mainCategory: string;
  subCategory: string;
  taxonomy_id: number;
}

export interface IIncident {
  id: string;
  title: string;
  content: string;
  post_date: Moment;
  post_status: string;
  icon_link: string;
  priority: string;
  bundleCodes?: string[];
}

export interface IChat {
  hasChat: boolean;
  hasChatBot: boolean;
  chatBotSite: string;
  chatQueueId: string;
  chatQueueKey: string;
}

export interface IFeedbackCategories {
  value: string;
  description: string;
  subArea: IFeedbackSubCategories[];
}

export interface IFeedbackSubCategories {
  value: string;
  description: string;
}

export interface IContact {
  id: string;
  post_title: string;
  post_content: string;
}

export interface IFooterMenu {
  url: string;
  title: string;
}

export interface IContactFormMapping {
  id: number,
  name: string
  description: string;
  parent: number,
  showFeedbackForm: string;
}

export interface IMaintenanceStatus {
  maintenenceActive: boolean;
  maintenenceText: string;
}

export interface IMaintenance {
  active: boolean;
  message: string;
}

export default class WPService {

  public fetch<T>(url, params: RequestInit = {}) {
    return fetch(url, params)
      .then(res => this.checkError(res))
      .then(res => res.json())
      .catch(error => {
        throw error;
      });
  }

  public sendFeedback(hostname: string, spid: string, sig: string, category: string, subCategory: string, feedback: string): Promise<any> {
    const url = `${buildApiUrl(hostname)}/wp-json/expose/feedback`;
    const options: RequestInit = {
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-score-spid-sig': sig,
      },
      body: JSON.stringify({
        customerId: spid.toString(),
        feedback_category: category,
        feedback_subcategory: subCategory,
        message: feedback,
      }),
      method: 'POST',
    };

    debug('sendFeedback()');
    debug('calling fetch(%s, %o)', url, options);

    return this.fetch(url, options);
  }

  public getFAQ(hostname: string): Promise<IFAQ> {
    const url = `${buildApiUrl(hostname)}/wp-json/expose/faq`;
    const options: RequestInit = {
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
      }
    };

    debug('getFAQ()');
    debug('calling fetch(%s, %o)', url, options);

    return this.fetch(url, options);
  }

  public getIncidents(hostname: string): Promise<any> {
    const url = `${buildApiUrl(hostname)}/wp-json/expose/incidents`;
    const options: RequestInit = {
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
      }
    };

    debug('getIncidents()');
    debug('calling fetch(%s, %o)', url, options);

    return this.fetch(url, options);
  }


  public getContact(hostname: string): Promise<any> {
    const url = `${buildApiUrl(hostname)}/wp-json/expose/contact`;
    const options: RequestInit = {
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
      }
    };

    debug('getContact()');
    debug('calling fetch(%s, %o)', url, options);

    return this.fetch(url, options);
  }


  public getChat(hostname: string): Promise<any> {
    const url = `${buildApiUrl(hostname)}/wp-json/expose/chat`;
    const options: RequestInit = {
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
      }
    };

    debug('getChat()');
    debug('calling fetch(%s, %o)', url, options);

    return this.fetch(url, options);
  }

  public getFeedbackCategories(hostname: string): Promise<any> {
    const url = `${buildApiUrl(hostname)}/wp-json/expose/feedback/categories`;
    const options: RequestInit = {
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
      }
    };

    debug('getFeedbackCategories()');
    debug('calling fetch(%s, %o)', url, options);

    return this.fetch(url, options);
  }

  public getFooterMenu(hostname: string): Promise<IFooterMenu[]> {
    const url = `${buildApiUrl(hostname)}/wp-json/expose/footermenu`;
    const options: RequestInit = {
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
      }
    };

    debug('getFooterMenu()');
    debug('calling fetch(%s, %o)', url, options);

    return this.fetch(url, options);
  }

  public getContactFormMapping(hostname: string): Promise<IContactFormMapping[]> {
    const url = `${buildApiUrl(hostname)}/wp-json/expose/faq-contact-mapping`;
    const options: RequestInit = {
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
      }
    };

    debug('getFooterMenu()');
    debug('calling fetch(%s, %o)', url, options);

    return this.fetch(url, options);
  }

  public getMaintenanceStatus(hostname: string): Promise<IMaintenanceStatus> {
    const url = `${buildApiUrl(hostname)}/wp-json/expose/settings`;
    const options: RequestInit = {
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
      }
    };

    debug('getMaintenanceStatus()');
    debug('calling fetch(%s, %o)', url, options);

    return this.fetch(url, options);
  }

  private checkError(res: Response): Response | any {
    if (res.status >= 200 && res.status < 300) {
      return res;
    }
    return Promise.reject(res);
  }
}
